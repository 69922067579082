// extracted by mini-css-extract-plugin
export var column = "ThanksProvider__column__KpPYM";
export var container = "ThanksProvider__container__JnI_5";
export var helpContainer = "ThanksProvider__helpContainer__xhL4R";
export var row = "ThanksProvider__row__YjSX3";
export var serviceBlock = "ThanksProvider__serviceBlock__ppR1_";
export var serviceText = "ThanksProvider__serviceText__VB8v0";
export var svgPathAdd = "ThanksProvider__svgPathAdd__xffB5";
export var svgPathAlert = "ThanksProvider__svgPathAlert__QvRA4";
export var svgPathDirectory = "ThanksProvider__svgPathDirectory__f8L0J";
export var svgPathMarketplace = "ThanksProvider__svgPathMarketplace__Zy8b5";
export var svgPathRealA = "ThanksProvider__svgPathRealA__n8vaF";
export var svgPathRealB = "ThanksProvider__svgPathRealB__lCRY0";
export var svgPathResources = "ThanksProvider__svgPathResources__uChcj";
export var thanks = "ThanksProvider__thanks__l4EdS";
export var thanksAlert = "ThanksProvider__thanksAlert__TLlz0";
export var thanksContainer = "ThanksProvider__thanksContainer__jGpds";
export var thanksIntro = "ThanksProvider__thanksIntro__weLJr";