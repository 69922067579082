// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../styleguide/components/Heading/H4.res.js";
import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as HelpBlock from "../help-block/HelpBlock.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ThanksProviderScss from "./ThanksProvider.scss";

var css = ThanksProviderScss;

function ThanksForSignupProvider$default(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(H2.make, {
                                          children: "Welcome to Datacenters.com"
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: "Datacenters.com is a technology marketplace and aggregator. We offer data center colocation, cloud, connectivity, hardware, software, and managed services from providers worldwide.",
                                          className: css.thanksIntro
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Svg.make, {
                                                  title: "Alert",
                                                  viewBoxWidth: "36",
                                                  viewBoxHeight: "36",
                                                  children: JsxRuntime.jsxs("g", {
                                                        children: [
                                                          JsxRuntime.jsx("circle", {
                                                                style: {
                                                                  stroke: "#9D4E09",
                                                                  strokeWidth: "2"
                                                                },
                                                                cx: "18",
                                                                cy: "18",
                                                                r: "16.8"
                                                              }),
                                                          JsxRuntime.jsx("circle", {
                                                                className: css.svgPathAlert,
                                                                cx: "18",
                                                                cy: "26",
                                                                r: "2"
                                                              }),
                                                          JsxRuntime.jsx("path", {
                                                                className: css.svgPathAlert,
                                                                d: "M15.2 12C15.2 9.3 16.1 8 18 8 19.9 8 20.8 9.3 20.8 12 20.8 14.7 19.9 18 18 22 16.1 18 15.2 14.7 15.2 12Z"
                                                              })
                                                        ],
                                                        fill: "none"
                                                      })
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "Your provider account will be activated after we make sure you are a real provider. Usually it takes up to 24 hours."
                                                })
                                          ],
                                          className: css.thanksAlert
                                        }),
                                    JsxRuntime.jsx(H3.make, {
                                          children: "How DataCenters.com Can Boost Your Sales"
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Svg.make, {
                                                  title: "Add info",
                                                  viewBoxWidth: "60",
                                                  viewBoxHeight: "60",
                                                  children: JsxRuntime.jsxs("g", {
                                                        children: [
                                                          JsxRuntime.jsx("path", {
                                                                style: {
                                                                  fill: "#FFF",
                                                                  stroke: "#98A0B0",
                                                                  strokeWidth: "3"
                                                                },
                                                                d: "M2 1.5C1.7 1.5 1.5 1.7 1.5 2L1.5 58C1.5 58.3 1.7 58.5 2 58.5L50 58.5C50.3 58.5 50.5 58.3 50.5 58L50.5 2C50.5 1.7 50.3 1.5 50 1.5L2 1.5Z"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathAdd,
                                                                height: "20",
                                                                width: "32",
                                                                rx: "1",
                                                                x: "10",
                                                                y: "10"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathAdd,
                                                                height: "6",
                                                                width: "32",
                                                                rx: "1",
                                                                x: "10",
                                                                y: "34"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathAdd,
                                                                height: "6",
                                                                width: "24",
                                                                rx: "1",
                                                                x: "10",
                                                                y: "44"
                                                              })
                                                        ],
                                                        fill: "none"
                                                      })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(H4.make, {
                                                          children: "Add Info about Your Company"
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: "Research data centers, cloud nodes, internet exchanges (IX), disaster recovery (DR) sites globally."
                                                        })
                                                  ],
                                                  className: css.serviceText
                                                })
                                          ],
                                          className: css.serviceBlock
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Svg.make, {
                                                  title: "Marketplace",
                                                  viewBoxWidth: "60",
                                                  viewBoxHeight: "60",
                                                  children: JsxRuntime.jsxs("g", {
                                                        children: [
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathMarketplace,
                                                                height: "26",
                                                                width: "26",
                                                                rx: "2",
                                                                x: "1.5",
                                                                y: "1.5"
                                                              }),
                                                          JsxRuntime.jsx("path", {
                                                                d: "M33 0L58 0C59.1 0 60 0.9 60 2L60 27C60 28.1 59.1 29 58 29L33 29C31.9 29 31 28.1 31 27L31 2C31 0.9 31.9 0 33 0ZM40 8C39.4 8 39 8.4 39 9L39 20C39 20.6 39.4 21 40 21L51 21C51.6 21 52 20.6 52 20L52 9C52 8.4 51.6 8 51 8L40 8Z",
                                                                fill: "#18C3BB"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathMarketplace,
                                                                height: "26",
                                                                width: "26",
                                                                rx: "2",
                                                                x: "1.5",
                                                                y: "32.5"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathMarketplace,
                                                                height: "26",
                                                                width: "26",
                                                                rx: "2",
                                                                x: "32.5",
                                                                y: "32.5"
                                                              })
                                                        ],
                                                        fill: "none"
                                                      })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(H4.make, {
                                                          children: "Add Products to the Marketplace"
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: "Click-to-buy colocation, cloud, internet and network connectivity, IT services, hardware, and software."
                                                        })
                                                  ],
                                                  className: css.serviceText
                                                })
                                          ],
                                          className: css.serviceBlock
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Svg.make, {
                                                  title: "Publish",
                                                  viewBoxWidth: "60",
                                                  viewBoxHeight: "60",
                                                  children: JsxRuntime.jsxs("g", {
                                                        children: [
                                                          JsxRuntime.jsx("path", {
                                                                style: {
                                                                  fill: "#FFF",
                                                                  stroke: "#98A0B0",
                                                                  strokeWidth: "3"
                                                                },
                                                                d: "M2 1.5C1.7 1.5 1.5 1.7 1.5 2L1.5 58C1.5 58.3 1.7 58.5 2 58.5L50 58.5C50.3 58.5 50.5 58.3 50.5 58L50.5 6.8C50.5 6.7 50.4 6.6 50.4 6.5L45.5 1.6C45.4 1.6 45.3 1.5 45.2 1.5L2 1.5Z"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathResources,
                                                                height: "4",
                                                                width: "28",
                                                                rx: "1",
                                                                x: "12",
                                                                y: "13"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathResources,
                                                                height: "4",
                                                                width: "28",
                                                                rx: "1",
                                                                x: "12",
                                                                y: "23"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathResources,
                                                                height: "4",
                                                                width: "28",
                                                                rx: "1",
                                                                x: "12",
                                                                y: "33"
                                                              }),
                                                          JsxRuntime.jsx("rect", {
                                                                className: css.svgPathResources,
                                                                height: "4",
                                                                width: "19",
                                                                rx: "1",
                                                                x: "12",
                                                                y: "43"
                                                              })
                                                        ],
                                                        fill: "none"
                                                      })
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(H4.make, {
                                                          children: "Publish Posts in Your Blog"
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: "Download data center checklists, plans, case studies, white papers, and reports from top providers."
                                                        })
                                                  ],
                                                  className: css.serviceText
                                                })
                                          ],
                                          className: css.serviceBlock
                                        })
                                  ],
                                  className: css.thanks
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(HelpBlock.make, {}),
                                  className: Cx.cx([
                                        css.column,
                                        css.helpContainer
                                      ])
                                })
                          ],
                          className: css.row
                        }),
                    className: css.container
                  }),
              className: css.thanksContainer
            });
}

var $$default = ThanksForSignupProvider$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
